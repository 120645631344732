<template>
  <div id="lnb">
    <h1>
      <!-- 로고 클릭 시 자사 웹사이트 홈으로 -->
      <a href="javascript:;" @click.prevent="movePage('/')">
        <img src="@/assets/img/admin/logo_white.png" alt="엔더스로고" />
      </a>
    </h1>

    <!-- 메뉴// -->
    <nav>
      <ul>
        <li :class="[navMenu.inquryConsultation, navMenu.inquryDetail]">
          <a href="javascript:;" @click.prevent="movePage('/admin/inquryConsultation')">문의상담 조회</a>
        </li>
        <li :class="[navMenu.applicantManagement, navMenu.applicantDetail]">
          <a href="javascript:;" @click.prevent="movePage('/admin/applicantManagement')">지원자 관리</a>
        </li>
        <li :class="[navMenu.portfolioManagement, navMenu.portfolioDetail, navMenu.enrollPortfolio]">
          <a href="javascript:;" @click.prevent="movePage('/admin/portfolioManagement')">포트폴리오 관리</a>
        </li>
        <li :class="[navMenu.history, navMenu.historyDetail, navMenu.enrollHistory]">
          <a href="javascript:;" @click.prevent="movePage('/admin/history')">연혁관리</a>
        </li>
      </ul>
    </nav>
    <!-- //메뉴 -->
    <button class="btn logout" @click.prevent="logout">로그아웃</button>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  data() {
    return {
      navMenu: {
        inquryConsultation: '',
        inquryDetail: '',
        applicantManagement: '',
        applicantDetail: '',
        portfolioManagement: '',
        portfolioDetail: '',
        enrollPortfolio: '',
        history: '',
        historyDetail: '',
        enrollHistory: ''
      },
    }
  },
  created() {
    // console.log(this.navMenu);
    this.navMenu.inquryConsultation = 'active'
  },
  watch: {
    '$route.path': function move() {
      this.resetMenuActive();
      this.setMenuActive(this.$route.path);
    },
  },
  methods: {
    movePage(path) {
      document.body.classList.remove('active');
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    logout(){
      this.$store.dispatch('logout');
      this.$router.push('/admin/login');
    },
    setMenuActive(path) {
      const pathArr = path.split('/');
      // console.log(pathArr[2]);
      this.navMenu[pathArr[2]] = 'active'
    },
    resetMenuActive() {
      this.navMenu.inquryConsultation = '';
      this.navMenu.inquryDetail = '';
      this.navMenu.applicantManagement = '';
      this.navMenu.applicantDetail = '';
      this.navMenu.portfolioManagement = '';
      this.navMenu.portfolioDetail = '';
      this.navMenu.enrollPortfolio = '';
      this.navMenu.history = '';
      this.navMenu.historyDetail = '';
      this.navMenu.enrollHistory = '';
    },
  },
};
</script>

<style scoped></style>
