<template>
  <div>
    <div class="wall"></div>
    <div class="load">
      <img src="@/assets/img/sub/loading_logo.png" alt="loading...">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
};
</script>

<style lang='scss' scoped>
.wall{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.2;
  z-index: 111111;
}
.load{
  position: fixed;
  width: 6rem;
  height: 6rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-size: 40%;
  z-index: 1111111;
  img{
    width: 100%;
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
