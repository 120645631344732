<template>
  <div id="wrap" class="admin">
    <Navigation />
    <router-view />
    <Loading v-if="asyncLoading" />
  </div>
</template>

<script>
import Navigation from '@/components/admin/AdminNav.vue';
import Loading from '@/components/Loading.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'AdminLayout',
  components: {
    Navigation,
    Loading
  },
  computed: {
    ...mapGetters({
      asyncLoading: 'asyncLoading',
    }),
  },
};
</script>
